var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "category-list"
  }, [_c('BaseRowSlider', {
    staticClass: "popular-category-slider",
    attrs: {
      "list": _vm.getCategory,
      "overflow": "unset"
    },
    on: {
      "is-end": _vm.isOverlayHandler
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var el = _ref.el;
        return [el.id === 'scrollbar_market' ? _c('a', {
          staticClass: "category-item",
          class: ("category-item_" + (el.key)),
          attrs: {
            "href": el.link
          }
        }, [_c('div', {
          staticClass: "category-list__img-wrap"
        }), _vm._v(" "), _c('span', {
          staticClass: "category-item__name"
        }, [_vm._v("Маркетплейс "), _c('br'), _vm._v(" от 05.ru")])]) : el.id !== 'scrollbar_app' ? _c('nuxt-link', {
          key: el.id,
          staticClass: "category-item",
          class: ("category-item_" + (el.key)),
          attrs: {
            "to": el.link
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.emitter.emit('mainpage/on-popular-category-click', {
                event: el.id
              });
            }
          }
        }, [_c('div', {
          staticClass: "category-list__img-wrap"
        }, [_c('img', {
          staticClass: "category-item__img",
          attrs: {
            "src": require(("~/assets/images/popular-category/" + (el.key) + ".jpg?original")),
            "srcset": ((require(("~/assets/images/popular-category/" + (el.key) + ".jpg?original"))) + " 1x, " + (require(("~/assets/images/popular-category/" + (el.key) + "@2x.jpg"))) + " 2x, " + (require(("~/assets/images/popular-category/" + (el.key) + "@3x.jpg"))) + " 3x"),
            "alt": el.name
          }
        })]), _vm._v(" "), _c('span', {
          staticClass: "category-item__name",
          class: [{
            'category-item_new': el.isNew
          }]
        }, [_vm._v(_vm._s(el.name))])]) : _c('a', {
          staticClass: "category-item category-item__app",
          attrs: {
            "href": el.link,
            "target": "_blank"
          },
          on: {
            "click": function ($event) {
              return _vm.emitter.emit('mainpage/on-popular-category-click', {
                event: el.id
              });
            }
          }
        }, [_c('img', {
          staticClass: "category-item__img category-item__app-img",
          attrs: {
            "src": require(("~/assets/images/popular-category/" + (el.key) + ".jpg?original")),
            "srcset": ((require(("~/assets/images/popular-category/" + (el.key) + ".jpg?original"))) + " 1x, " + (require(("~/assets/images/popular-category/" + (el.key) + "@2x.jpg"))) + " 2x, " + (require(("~/assets/images/popular-category/" + (el.key) + "@3x.jpg"))) + " 3x"),
            "alt": el.name
          }
        }), _vm._v(" "), _c('span', {
          staticClass: "category-item__name",
          class: [{
            'category-item_new': el.isNew
          }]
        }, [_vm._v(_vm._s(el.name))])])];
      }
    }, {
      key: "nav",
      fn: function (_ref2) {
        var prev = _ref2.prev,
            next = _ref2.next,
            isStart = _ref2.isStart;
        return [_vm._t("nav", function () {
          return [_c('div', {
            staticClass: "category-list__btn category-list__btn_prev",
            class: {
              'category-list__btn_hide': isStart
            },
            on: {
              "click": function ($event) {
                return prev(1);
              }
            }
          }), _vm._v(" "), _c('div', {
            staticClass: "category-list__btn category-list__btn_next",
            class: {
              'category-list__btn_hide': _vm.isOverlayHide
            },
            on: {
              "click": function ($event) {
                return next(1);
              }
            }
          })];
        }), _vm._v(" "), _c('div', {
          staticClass: "overlay",
          class: [{
            overlay_hide: _vm.isOverlayHide
          }]
        }), _vm._v(" "), _c('div', {
          staticClass: "overlay",
          class: [{
            overlay__left: !isStart
          }]
        })];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }